/* You can add global styles to this file, and also import other style files */
@import 'variables.scss';

html, body {
    height: 100%;
}

body {
    background-color: white;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    margin: 0;
    overflow-x: hidden;

    a {
        color: $color-kitcheck-link-blue;
        text-decoration: underline;
        cursor: pointer;

        &.no-underline {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    /* Removes the chrome autofill banana yellow background on inputs */
    input:-webkit-autofill {
        -webkit-box-shadow: inset 0 0 0px 9999px white;
    }

    /* Styles the scrollbar */
    section {
        &::-webkit-scrollbar-track {
            background-color: $color-kitcheck-light-blue
        }
        &::-webkit-scrollbar {
            width: 8px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background-image: -webkit-gradient(
                linear,
                left bottom,
                right top,
                color-stop(0.44, rgb(230, 43, 136)),
                color-stop(0.72, rgb(73,125,189)),
                color-stop(0.86, rgb(28,58,148))
            );
        }
    }

    /* Override material component styles */
    .mat-snack-bar-container {
        max-width: 50vw !important;
    }

    mat-form-field.error-hint {
        mat-hint,
        mat-label,
        .mat-form-field-outline,
        &.mat-focused .mat-form-field-outline-thick {
            color: red;
        }

        .mat-form-field-underline, &.mat-focused .mat-form-field-ripple {
            background-color: red;
        }

        mat-hint, .mat-form-field-label {
            color: red !important;
        }
    }

    .mat-card-title {
        color: $dark-grey;
        font-size: 16px;
        font-weight: bold;
    }

    .mat-dialog-container, .cdk-overlay-pane {
        max-width: none !important;
    }
}

.success-snackbar {
    background-color: $success;
    color: $dark-grey;
    margin-bottom: 70px !important;
}
